import React from 'react';

function Dashboard() {
  return (
    <div>
      <h1>Welcome to the Dashboard</h1>
      <p>This is a protected route. You must be logged in to view this content.</p>
    </div>
  );
}

export default Dashboard;
