import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import ProtectedRoute from './ProtectedRoute';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { Helmet } from 'react-helmet'; // Import Helmet


function App() {
  const location = useLocation();
  const isLogin = location.pathname === '/login';

  return (
    <div className={isLogin ? 'login-background' : 'dashboard-background'}>
{/* Set dynamic title based on the route */}
      <Helmet>
        <title>{isLogin ? 'Login - Juicd.io' : 'Dashboard - Your Juicd.io'}</title>
      </Helmet>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
}

function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default MainApp;
